var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty" }, [
      _c("img", {
        staticStyle: { width: "100px" },
        attrs: { src: require("@/assets/svg/empty.svg"), alt: "" }
      }),
      _c("p", { staticStyle: { "padding-right": "5px" } }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }